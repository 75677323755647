<template>
  <div class="activityManagement">
    <div class="header-button-query">
      <div class="header-button">
        <el-button type="success"
                   icon="el-icon-plus"
                   v-if="roleType"
                   @click="addActivity">添加活动</el-button>
        <el-button type="info"
                   @click="downFile">导出</el-button>
      </div>
      <div>
        <el-select placeholder="请选择状态"
                   v-if="roleType"
                   clearable
                   class="header-query-frame"
                   v-model="queryState">
          <el-option v-for="state in stateList"
                     :key="state.value"
                     :label="state.label"
                     :value="state.value"></el-option>
        </el-select>
        <el-date-picker v-model="queryTime"
                        type="daterange"
                        class="header-query-frame"
                        range-separator="至"
                        value-format="yyyy-MM-dd"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
        <el-input placeholder="请输入关键字"
                  class="header-query-frame"
                  v-model="queryName"></el-input>
        <el-button type="primary"
                   @click="loadActivityData(1)">查 询</el-button>
      </div>
    </div>
    <el-table :data="activityDataList">
      <el-table-column prop="title"
                       align="center"
                       label="标题"></el-table-column>
      <el-table-column prop="opername"
                       align="center"
                       label="发布人"></el-table-column>
      <el-table-column prop="opertime"
                       align="center"
                       label="发布时间"></el-table-column>
      <el-table-column prop="statusStr"
                       align="center"
                       v-if="roleType"
                       label="状态"></el-table-column>
      <el-table-column :width="roleType?'220px':'120px'"
                       align="center"
                       label="操作">
        <template slot-scope="scope">
          <div v-if="roleType">
            <el-button type="primary"
                       size="mini"
                       :disabled="scope.row.status!==1"
                       @click="editActivity(scope.row)">编 辑</el-button>
            <el-button type="primary"
                       :disabled="scope.row.status!==1"
                       size="mini"
                       @click="pushActivity(scope.row.id)">发 布</el-button>
            <el-button type="danger"
                       size="mini"
                       @click="deleteActivity(scope.$index,scope.row.id)">删 除</el-button>
          </div>
          <div v-else>
            <el-button type="primary"
                       @click="editActivity(scope.row)">查 看</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="activityDataVisible"
               width="600px"
               :title="activityDataForm.id!==-1?'活动信息':'添加活动信息'">
      <el-form label-width="100px"
               ref="activityDataForm"
               :rules="activityDataFormRules"
               :model="activityDataForm">
        <el-form-item label="主题"
                      prop="title">
          <el-input v-model="activityDataForm.title"
                    :disabled="!roleType"
                    placeholder="请输入主题名称"></el-input>
        </el-form-item>
        <el-form-item label="内容"
                      prop="content">
          <el-input type="textarea"
                    :disabled="!roleType"
                    autosize
                    placeholder="请输入内容"
                    v-model="activityDataForm.content"></el-input>
        </el-form-item>
        <el-form-item label="添加附件"
                      prop="fileUrl">
          <el-upload action="/api/base/uploadByOss.do"
                     :headers="{Authorization:token}"
                     :limit="1"
                     :on-remove="onRemoveFile"
                     ref="upload"
                     :on-success="enclosureUploadSuccess"
                     :data="{path:'activityManagement',name:''}"
                     :show-file-list="true">
            <el-button type="primary"
                       icon='el-icon-upload2'>导 入</el-button>
          </el-upload>
          <el-button type="primary"
                     class="load-file"
                     @click="loadFile"
                     v-if="activityDataForm.fileUrl">下载附件</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="activityDataVisible=false">{{roleType?'取 消':'确 定'}}</el-button>
        <el-button v-if="roleType"
                   @click="addActivityDataConfirm"
                   type="primary">确 定</el-button>
      </div>
    </el-dialog>
    <el-pagination layout="prev,pager,next"
                   background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadActivityData"></el-pagination>
  </div>
</template>

<script>
export default {
  name: "activityManagement",
  data() {
    return {
      queryState: "",
      queryTime: "",
      queryName: "",
      page: {
        currentPage: 1,
        pageCount: 0,
      },
      activityDataList: [],
      roleType: false,
      managementModule: JSON.parse(sessionStorage.getItem("userInfo")).nav1,
      activityDataVisible: false,
      activityDataForm: {
        title: "",
        fileUrl: "",
        content: "",
        id: -1,
        state: "",
      },
      activityDataFormRules: {
        title: [{ required: true, message: "请输入主题名称", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        // fileUrl: [{ required: true, message: "请上传资料" }],
      },
      stateList: [
        { label: "全部", value: "" },
        { label: "未发布", value: 1 },
        { label: "已发布", value: 2 },
      ],
      token: sessionStorage.getItem("token"),
    };
  },
  created() {
    //判断是否为管理员，1601为大部队管理的活动管理页面id
    for (let i = 0; i < this.managementModule.length; i++) {
      if (this.managementModule[i].id === 1601) {
        this.roleType = true;
        this.tableType = 0;
      }
    }
    this.loadActivityData(1);
  },
  methods: {
    loadActivityData(page) {
      let data = { type: 3, indexNo: page }; //3固定是大部队管理的活动管理
      if (!this.roleType) {
        data.status = 2;
      }
      if (this.queryState) {
        data.status = this.queryState;
      }
      if (this.queryName) {
        data.name = this.queryName;
      }
      if (this.queryTime) {
        data.start_time = this.queryTime[0];
        data.end_time = this.queryTime[1];
      }
      this.$post("/base/queryActivityAdmin.do", data)
        .then((res) => {
          this.page.currentPage = res.data.indexNo;
          this.page.pageCount = res.data.pageCount;
          this.activityDataList = res.data.rows;
        })
        .catch((err) => {
          this.$message({
            type: "danger",
            message: err.message,
          });
        });
    },
    addActivity() {
      this.activityDataVisible = true;
      this.$nextTick(() => {
        this.$refs["activityDataForm"].resetFields();
        this.activityDataForm.id = "";
        this.$refs.upload.clearFiles();
      });
    },
    addActivityDataConfirm(type) {
      //0添加，1编辑，2发布
      this.$refs["activityDataForm"].validate((valid) => {
        if (valid) {
          let data = {
            title: this.activityDataForm.title,
            content: this.activityDataForm.content,
            url: this.activityDataForm.fileUrl,
            type: 3,
          };
          let apiUrl = "/base/insertActivityAdmin.do";
          if (this.activityDataForm.id) {
            apiUrl = "/base/updateActivityAdmin.do";
            data = {
              title: this.activityDataForm.title,
              content: this.activityDataForm.content,
              url: this.activityDataForm.fileUrl,
              id: this.activityDataForm.id,
            };
          }
          this.$confirm("是否确认该操作", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$post(apiUrl, data)
                .then((res) => {
                  this.activityDataVisible = false;
                  this.$message({
                    type: "success",
                    message: res.message,
                  });
                  this.loadActivityData(1);
                })
                .catch((err) => {
                  this.activityDataVisible = false;
                  this.$message({
                    type: "warning",
                    message: err.message,
                  });
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消该操作",
              });
            });
        } else {
          return false;
        }
      });
    },
    editActivity(row) {
      this.activityDataVisible = true;
      this.$nextTick(() => {
        this.$refs.upload.clearFiles();
        this.activityDataForm.id = row.id;
        this.activityDataForm.title = row.title;
        this.activityDataForm.content = row.content;
        this.activityDataForm.fileUrl = row.url;
        this.activityDataForm.state = row.status;
      });
    },
    pushActivity(id) {
      this.$confirm("是否确认发布该活动", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(id);
          this.$post("/base/updateActivityAdmin.do", { id, status: 2 })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.loadActivityData(1);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消发布",
          });
        });
    },
    deleteActivity(index, id) {
      this.$confirm("是否确认删除该活动", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(id);
          this.$post("/base/deleteActivityAdmin.do", { id })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              this.activityDataList.splice(index, 1);
            })
            .catch((err) => {
              this.$message({
                type: "warning",
                message: err.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    enclosureUploadSuccess(res, file) {
      this.activityDataForm.fileUrl = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.activityDataForm.fileUrl);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.activityDataForm.fileUrl = "";
    },
    downFile() {
      const that = this;
      let paramas = new FormData();
      paramas.append("type", 3); //3固定是大部队管理的活动管理
      paramas.append("name", this.queryName);
      paramas.append("status", this.queryState);
      if (this.queryTime[0]) {
        paramas.append("start_time", this.queryTime[0]);
        paramas.append("end_time", this.queryTime[1]);
      }
      this.$postFile("/base/queryActivityAdmin.do", paramas)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "导出";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
.header-button-query {
  display: flex;
  justify-content: space-between;
}
.header-button {
  width: 230px;
}
.header-query-frame {
  margin: 0 5px;
}
.load-file {
  margin: 10px 0 0 54px;
}
</style>
